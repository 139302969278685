<template>
  <b-container>
    <b-row>
      <b-col class="mb-2" cols="12" lg="6">
        <h1 class="font-weight-bold mb-0 text-primary">Audits</h1>
      </b-col>
      <b-col class="mb-2 mb-md-4" cols="12" lg="6">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <b-input-group>
              <b-form-datepicker :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" v-model="filterStartDate"></b-form-datepicker>
              <div class="input-group-prepend input-group-append"><div class="input-group-text">-</div></div>
              <b-form-datepicker :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" v-model="filterEndDate"></b-form-datepicker>
            </b-input-group>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col class="text-center" cols="12">
        <b-spinner class="mx-auto" variant="secondary" />
      </b-col>
    </b-row>
    <b-row v-if="!isLoading && data.length">
      <b-col cols="12">
        <b-card class="mb-2" :key="`audit-${item.id}`" v-for="item in data">
          <b-card-title><router-link :to="`/user/${item.causer.id}`" v-if="item.causer">{{ [ get(item, 'causer.forename'), get(item, 'causer.surname') ].join(' ') }}</router-link><span v-if="!item.causer">System</span> {{ item.description }} {{ prettySubjectType(item.subject_type) }}</b-card-title>
          <b-card-sub-title v-if="item.subject_type !== 'App\\Models\\Payment'"><span v-if="!subjectLink(item.subject_type, get(item, 'subject.id'))">{{ subjectName(item.subject_type, item.subject) }}</span><router-link :to="subjectLink(item.subject_type, get(item, 'subject.id'))" v-if="subjectLink(item.subject_type, get(item, 'subject.id'))">{{ subjectName(item.subject_type, item.subject) }}</router-link></b-card-sub-title>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="!data.length && !isLoading">
      <b-col class="text-center" cols="12">
        <b-card class="py-5 text-center">
          <p class="mb-4"><b-icon class="display-1 text-dark" icon="cloud-arrow-down"></b-icon></p>
          <p class="font-weight-normal h5 mb-0 text-dark">Could not find any audit log items that match the given criteria.</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="data && !isLoading && pagination.per < pagination.total">
      <b-col cols="12">
        <b-pagination @change="onPaginate" :per-page="pagination.per" :total-rows="pagination.total" v-model="pagination.current"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'
export default {
  mixins: [momentMixin],
  created () {
    this.fetchManyAudits({ startDate: this.filterStartDate, endDate: this.filterEndDate })
  },
  data () {
    return {
      date: this.$route.query.date ? this.moment(this.$route.query.date).toDate() : this.moment().subtract(1, 'month').toDate(),
      showOptions: ['missed'],
      payments: [{
        amount: 30,
        id: 0
      }]
    }
  },
  computed: {
    ...mapGetters('audits', ['data', 'isLoading', 'pagination']),
    filterStartDate: {
      get () {
        return this.moment(this.date).toDate()
      },
      set (value) {
        this.date = value
      }
    },
    filterEndDate: {
      get () {
        return this.moment(this.date).add(1, 'month').subtract(1, 'day').toDate()
      },
      set (value) {
        this.date = this.moment(value).subtract(1, 'month').add(1, 'day').toDate()
      }
    }
  },
  watch: {
    date () {
      this.$router.push({ query: { date: this.moment(this.date).format('YYYY-MM-DD') } })
    },
    '$route' () {
      this.fetchManyAudits({ startDate: this.filterStartDate, endDate: this.filterEndDate })
    }
  },
  methods: {
    get,
    ...mapActions('audits', ['fetchManyAudits']),
    onPaginate (value) {
      this.fetchManyAudits({ startDate: this.filterStartDate, endDate: this.filterEndDate, page: value })
    },
    prettySubjectType (subjectType) {
      return subjectType.replace('App\\Models\\', '').replace(/([A-Z]{1})/g, ' $1').trim().toLowerCase()
    },
    subjectLink (subjectType, id) {
      switch (subjectType) {
        case 'App\\Models\\EnergyCustomer':
          return `/energy/customers/${id}`
        case 'App\\Models\\SecurityCustomer':
          return `/security/customers/${id}`
        case 'App\\Models\\EnergySite':
          return `/energy/sites/${id}`
        case 'App\\Models\\SecuritySite':
          return `/security/sites/${id}`
        case 'App\\Models\\Inventory':
          return `/security/inventory/${id}`
        case 'App\\Models\\Contract':
          return `/energy/contract/${id}`
        case 'App\\Models\\Meter':
          return `/energy/meters/${id}`
        case 'App\\Models\\Supplier':
          return `/energy/suppliers/${id}`
        case 'App\\Models\\User':
          return `/admin/users/${id}`
        default:
          return null
      }
    },
    subjectName (subjectType, subjectData) {
      switch (subjectType) {
        case 'App\\Models\\Address':
          return subjectData.address_snippet
        case 'App\\Models\\Contact':
        case 'App\\Models\\User':
          return [subjectData.forename, subjectData.surname].join(' ')
        case 'App\\Models\\EnergyCustomer':
        case 'App\\Models\\SecurityCustomer':
          return subjectData.company_name
        case 'App\\Models\\EnergySite':
        case 'App\\Models\\SecuritySite':
          return `${subjectData.customer.company_name} (${subjectData.address.postcode})`
        case 'App\\Models\\Inventory':
        case 'App\\Models\\Supplier':
          return subjectData.name
        case 'App\\Models\\Meter':
          return subjectData.type === 'electric'
            ? `${subjectData.top_line ? [subjectData.top_line.slice(0, 2), subjectData.top_line.slice(2, 5), subjectData.top_line.slice(5, 8)].join(' ') + ' / ' : ''}${[subjectData.mpan_core.slice(0, 2), subjectData.mpan_core.slice(2, 6), subjectData.mpan_core.slice(6, 10), subjectData.mpan_core.slice(10, 13)].join(' ')}`
            : subjectData.type === 'gas'
              ? subjectData.mprn
              : subjectData.spid
        default:
          return `View ${this.prettySubjectType(subjectType)}`
      }
    }
  }
}
</script>

<style>

</style>
