import pagination from '../../utils/pagination'
import {
  AUDITS_FETCH_MANY_REQUEST, AUDITS_FETCH_MANY_SUCCESS, AUDITS_FETCH_MANY_FAILURE,
  AUDITS_FETCH_REQUEST, AUDITS_FETCH_SUCCESS, AUDITS_FETCH_FAILURE
} from './types'

export default {
  [AUDITS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [AUDITS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
    state.validationErrors = {}
  },
  [AUDITS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [AUDITS_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [AUDITS_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [AUDITS_FETCH_FAILURE] (state) {
    state.loading = false
  }
}
