<template>
  <router-view />
</template>

<script>
import auditsModule from '../../store/audits'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('audits')) {
      this.$store.registerModule('audits', auditsModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('audits')) {
      this.$store.unregisterModule('audits')
    }
  }
}
</script>
